<template>
  <v-app light>
    <the-nav-bar :scroll-position="scrollPosition"></the-nav-bar>
    <the-features-section
      :screenshots="screenshots"
      class="mt-8"
    ></the-features-section>
    <TheContactUsSection class="custom-container px-4 mx-auto mt-8" />
  </v-app>
</template>

<script>
import "@/assets/css/global_styles.css";
import TheFeaturesSection from "@/components/TheFeaturesSection";
import TheContactUsSection from "@/components/TheContactUsSection";
import TheNavBar from "@/components/TheNavBar";

export default {
  name: "App",
  components: {
    TheNavBar,
    TheContactUsSection,
    TheFeaturesSection
  },
  data: () => ({
    title: "Monomer Software",
    screenshots: [
      {
        title: "Stilt - Work Management Platform",
        description:
          "Stilt is a mobile and web platform for managing work on site, with an emphasis on 3rd-party contractor work, such as scaffolding, insulation, blasting, and more.",
        url: "https://stilt.monomersoftware.com",
        src: require("@/assets/images/stilt_image.png")
      },
      {
        title: "Canopy A.I. Vision Platform",
        description:
          "Canopy is a hardware & software platform that combines self-cleaning industrial camera hardware with a flexible software platform to easily deploy A.I. vision across a facility.",
        url: "https://canopy-vision.ai",
        src: require("@/assets/images/canopy_image.png")
      }
    ],
    scrollPosition: null
  }),
  created: function() {
    document.title = this.title;
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    }
  }
};
</script>
