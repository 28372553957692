<template>
  <v-row id="contact-us" no-gutters justify="center" class="mx-0">
    <v-col cols="12" md="6" class="d-flex flex-column">
      <div class="text-24 text-semi-bold text-center">Company info</div>
      <div class="mt-8">
        Monomer Software is a Florida-based software company that specializes in
        developing mobile applications and A.I. Vision Systems for industrial
        facilities. Contact us to learn more about our products and services.
      </div>
    </v-col>
    <v-col cols="12" md="6" class="mt-4 mt-md-0">
      <div class="text-24 text-semi-bold text-center">Contact us</div>
      <div>
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-action>
              <v-icon class="text--darken-4">
                {{ mdiPhone }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>727-514-4811</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon class="text--darken-4">
                {{ mdiMapMarker }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Tampa, FL</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon class="text--darken-4">
                {{ mdiEmail }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                support@monomersoftware.com
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPhone, mdiMapMarker, mdiEmail } from "@mdi/js";
export default {
  name: "TheContactUsSection",
  data() {
    return {
      mdiPhone,
      mdiMapMarker,
      mdiEmail
    };
  }
};
</script>
