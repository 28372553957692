<template>
  <g>
    <circle r="2" transform="matrix(-1 0 0 1 12 12)" />
    <path
      fill-rule="evenodd"
      d="M7.534 4.712a.5.5 0 00-.353.854l3.357 3.358a.5.5 0 00.354.146h2.238a.5.5 0 00.5-.5V5.212a.5.5 0 00-.5-.5H7.534zm9.955.728a.5.5 0 00-.921-.073l-2.156 4.231a.5.5 0 00-.03.382l.692 2.129a.5.5 0 00.63.32l3.193-1.037a.5.5 0 00.321-.63l-1.73-5.322zm2.577 9.175a.5.5 0 01-.192.521l-4.528 3.29a.5.5 0 01-.698-.111l-1.974-2.717a.5.5 0 01.11-.698l1.812-1.316a.5.5 0 01.372-.09l4.69.744a.5.5 0 01.408.377zm-8.677 5.79a.5.5 0 00.788-.483l-.743-4.69a.5.5 0 00-.2-.326l-1.81-1.316a.5.5 0 00-.7.11l-1.973 2.717a.5.5 0 00.11.698l4.528 3.29zM3.91 14.5a.5.5 0 01-.15-.535l1.729-5.322a.5.5 0 01.63-.321L9.312 9.36a.5.5 0 01.321.63l-.692 2.13a.5.5 0 01-.248.29l-4.231 2.156a.5.5 0 01-.552-.065z"
      clip-rule="evenodd"
    />
    <path
      fill-rule="evenodd"
      d="M20.5 18.983A10.978 10.978 0 0112 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11c0 2.024-.547 3.92-1.5 5.549v2.744l.5.5V22.5h-2v-1.707l.5-.5v-1.31zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      clip-rule="evenodd"
    />
  </g>
</template>

<script>
export default {
  name: "IconHvac"
};
</script>
