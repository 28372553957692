<template>
  <div class="rounded overflow-hidden elevation-1">
    <div class="d-flex flex-column align-center justify-center px-2 pt-2">
      <v-img
        :src="src"
        alt="Feature image"
        height="400"
        width="600"
        fit
      ></v-img>
    </div>
    <div class="text-h6 px-4 pt-4 text-center">
      <slot name="title">Feature Title</slot>
    </div>
    <div class="pt-2 px-4 pb-4 text-center">
      <slot name="description"> Feature description goes here </slot>
    </div>
    <div class="pt-2 px-4 pb-4 text-center">
      <v-btn color="primary" a :href="url"> Go To Product Site </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    buttonColor() {
      return "primary";
    }
  }
};
</script>

<style scoped></style>
