import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const customThemes = {
  themes: {
    light: {
      primary: "#35B5B2"
    }
  }
};

Vue.use(Vuetify);

export default new Vuetify({ theme: customThemes });
