<!--suppress HtmlUnknownAnchorTarget -->
<template>
  <div
    id="navbar"
    class="nav-bar-position bg-black-opacity-80 d-flex flex-grow-1 align-center justify-center justify-lg-end text-center white--text pr-lg-6 nav-bar-animation"
    :class="{ showNavClass: showNavBar }"
  >
    <div class="padding-5px link-hover-effect">
      <a
        href="https://stilt.monomersoftware.com"
        class="white--text text-decoration-none"
      >
        Stilt
      </a>
    </div>
    <div class="padding-5px">|</div>
    <div class="padding-5px link-hover-effect">
      <a
        href="https://canopy-vision.ai"
        class="white--text text-decoration-none"
      >
        Canopy
      </a>
    </div>
    <div class="padding-5px">|</div>
    <div class="padding-5px link-hover-effect">
      <a href="#contact-us" class="white--text text-decoration-none">
        Contact Us
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scrollPosition: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      previousScrollPosition: 0
    };
  },
  computed: {
    showNavBar() {
      const newPosition = this.scrollPosition ? this.scrollPosition : 0;
      const isScrollingDown =
        newPosition > 100 && newPosition > this.previousScrollPosition;
      this.saveScrollPosition();
      return !isScrollingDown;
    }
  },
  methods: {
    saveScrollPosition() {
      this.previousScrollPosition = this.scrollPosition;
    }
  }
};
</script>

<style scoped>
.nav-bar-position {
  height: 40px;
  width: 100%;
  top: -40px;
  left: 0;
  z-index: 999;
  position: fixed;
}

.nav-bar-animation {
  transition: transform 500ms;
}

/*noinspection CssUnusedSymbol*/
.showNavClass {
  transform: translateY(40px);
}

.padding-5px {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
