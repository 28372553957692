<template>
  <div>
    <div class="custom-container mx-auto mt-2">
      <v-col class="d-flex justify-center align-center">
        <v-row>
          <v-img
            src="../assets/images/MonomerSoftware_logo-color.jpg"
            height="150"
            width="600"
            contain
          ></v-img>
        </v-row>
      </v-col>
      <div class="pt-2 px-8 pb-4 text-center">
        For more information on our existing product offerings, please visit one
        of the following links below.
      </div>
      <v-row class="mx-0">
        <v-col
          v-for="item in screenshots"
          :key="item.title"
          cols="12"
          md="6"
          class="px-8"
        >
          <feature-card :src="item.src" :url="item.url">
            <template v-slot:title>{{ item.title }}</template>
            <template v-slot:description>{{ item.description }}</template>
          </feature-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import FeatureCard from "@/components/FeatureCard";

export default {
  components: { FeatureCard },
  props: {
    screenshots: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped></style>
