import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import firebase from "firebase/app";
import "firebase/analytics";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

Vue.config.productionTip = false;

// Initialize Firebase Analytics
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDs0qYyuccs8Pd1dm-Xj9-4vK107GgztfE",
  authDomain: "monomer-software-f1dd2.firebaseapp.com",
  projectId: "monomer-software-f1dd2",
  storageBucket: "monomer-software-f1dd2.appspot.com",
  messagingSenderId: "346608280690",
  appId: "1:346608280690:web:ce96583fc1c6ef95650eeb",
  measurementId: "G-9L6KJKY5L8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// Globally registering icon components.
const requireComponent = require.context(
  // The relative path of the components folder.
  "./components/icons",
  // Whether to look in subfolders
  false,
  // The regular expression used to match components.
  /Icon[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach(fileName => {
  // Get component config.
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

new Vue({
  vuetify,
  render: h => h(App)
}).$mount("#app");
